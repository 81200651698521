.select {
    position: relative;
}
.select>select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}
.select select::-ms-expand {
    display: none;
}
.select:before {
    font-family: "iconfont";
    content: var(--icon-arrow-right);
    font-weight: 600;
    color: var(--color-default);
    speak: none;
    position: absolute;
    pointer-events: none;
    font-size: calc(12rem / 16);
    top: 50%;
    right: calc(14rem / 16);
    transform: translateY(-50%) rotate(90deg);
}
select.form-control:not([size]):not([multiple]) {
    height: calc(42rem / 16);
}