@media screen and (min-width: 768px) {
    .content-connector {
        height: calc(190rem / 16);
        width: 1px;
        background-color: var(--color-default);
        margin: calc(10rem / 16) auto 0;
        transform: scaleY(1);
        transform-origin: top center;
        opacity: 1;
        will-change: transform;
    }
    .content-connector--fixed {
        position: absolute;
        bottom: 0;
        left: 50%;
    }
}