.step {
    font-size: calc(20rem / 16);
    line-height: calc(25/20);
    letter-spacing: calc(1rem / 16);
    text-align: right;

    @media screen and (min-width: 1200px) {
        font-size: calc(24rem / 16);
        line-height: calc(40/30);
        letter-spacing: calc(1.5rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(30rem / 16);
    }
}
.step__icon {
    position: relative;
    font-size: calc(100rem / 16);
    color: var(--color-secondary);
    margin-bottom: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(255rem / 16);
        margin-bottom: calc(75rem / 16);
    }
}
.step__icon__count {
    font-size: calc(50rem / 16);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: calc(18rem / 16);
    right: calc(18rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(100rem / 16);
        left: calc(40rem / 16);
        right: calc(40rem / 16);
    }
}

.step--icon-left {
    text-align: left;
}
.step--icon-left .step__icon {
    color: var(--color-primary);

    @media screen and (min-width: 768px) {
        bottom: calc(-30rem / 16);
    }
}
.step--icon-left .step__icon:before {
    display: inline-block;
    transform: scaleX(-1);
}