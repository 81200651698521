.navbar.navbar-main {
    /*position: fixed;*/
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    padding: calc(15rem / 16);
    transition: background 200ms ease-in-out;
    
    @media screen and (min-width: 768px) {
        padding: calc(40rem / 16) 0;
    }
}
.navbar-brand {
    position: relative;
    margin: 0 auto;
    width: calc(152rem / 16);

    @media screen and (min-width: 768px) {
        margin: 0;
    }
}
.navbar-brand__img {
    max-width: 100%;
    max-height: 100%;
}