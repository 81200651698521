.form-control {
    background: transparent;
    border: 0;
    border-bottom: calc(1.5rem / 16) solid var(--color-default);
    height: calc(42rem / 16);
    padding: calc(8rem / 16) 0 calc(8rem / 16);
    font-size: calc(15rem / 16);
    line-height: calc(20/15);
    letter-spacing: calc(2rem / 16);
}
.form-control:-webkit-autofill,
.form-control:focus {
    background-color: transparent;
    border-color: var(--color-default);
}

.form-control::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: var(--color-default);
}
.form-control::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: var(--color-default);
    opacity:  1;
}
.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--color-default);
}

/* full bordered inputs */
.form-control--bordered {
    border: calc(1.5rem / 16) solid var(--color-default);
    padding: calc(10rem / 16);
    border-radius: calc(5rem / 16);
}

/* control size */
.form-group {
    margin-bottom: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(35rem / 16);
    }
}
.input-group-append .btn {
    z-index:auto;
}
.form-info {
    font-size: calc(12rem / 16);
    line-height: calc(17/12);
}
/* control label */
.control-label {
    font-family: var(--font-default-bold);
    font-weight: 400;
    font-size: calc(15rem / 16);
    line-height: calc(20/15);
    letter-spacing: calc(2rem / 16);
    margin-bottom: calc(10rem / 16);
}