body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: calc(16rem / 16);
    line-height: calc(20/16);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
    }
}

b,
strong,
.strong,
.font-default-bold {
    font-family: var(--font-default-bold);
    font-weight: normal;
}
.font-default {
    font-family: var(--font-default);
    font-weight: 400;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: var(--font-default-bold);
    font-weight: 400;
    margin-top: 0;
    display: block;
    color: inherit;
}

h1, .h1 {
    font-size: calc(35rem / 16);
    line-height: calc(40/35);
    letter-spacing: calc(1.75rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(100rem / 16);
        line-height: calc(127/100);
        letter-spacing: calc(5rem / 16);
    }
}
h2, .h2 {
    font-size: calc(30rem / 16);
    line-height: calc(40/30);
    letter-spacing: calc(1.5rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(60rem / 16);
        line-height: calc(76/60);
        letter-spacing: calc(3rem / 16);
    }
}
h3, .h3 {
    font-size: calc(20rem / 16);
    line-height: calc(38/30);
    letter-spacing: calc(1.5rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(30rem / 16);
    }
}
h4, .h4 {
    font-size: calc(25rem / 16);
    line-height: calc(40/25);
    letter-spacing: calc(1.25rem / 16);
}
h5, .h5 {
    text-transform: uppercase;
    font-size: calc(12rem / 16);
    line-height: calc(15/12);
    letter-spacing: calc(.6rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
        line-height: calc(26/20);
        letter-spacing: calc(1rem / 16);
    }
}
.intro {
    font-size: calc(20rem / 16);
    line-height: calc(25/20);
    letter-spacing: calc(1rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(26rem / 16);
        line-height: calc(38/30);
        letter-spacing: calc(1.5rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(30rem / 16);
    }
}