.btn {
    font-family: var(--font-default-bold);
    font-weight: 400;
    font-size: calc(15rem / 16);
    letter-spacing: calc(2rem / 16);
    border-radius: calc(30rem / 16);
    text-transform: uppercase;
    padding: calc(18rem / 16) calc(40rem / 16);
}
.btn-no-styling,
.btn.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}