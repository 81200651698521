.clickable { cursor: pointer;}

/*Text Helper*/
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.text-transform-none {text-transform: none;}

/*Text Color Helper*/
.text-primary-hover:hover { color: var(--color-primary); }
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default); }
.text-dark-grey { color: var(--color-dark-grey);}
.text-secondary, .text-secondary-hover:hover { color: var(--color-secondary);}


.text-red { color: #EB5A4B;}
.text-yellow { color: #FAB95A;}
.text-green { color: #AFD269;}
.text-blue { color: #82CAC3;}

/*Background Color Helper*/
.bg-light-grey {background: var(--color-light-grey)}


/* letter spacings */
.letter-spacing-0 {letter-spacing: normal;}
.letter-spacing-2 {letter-spacing: calc(2rem / 16);}
.letter-spacing-3 {letter-spacing: calc(3rem / 16);}

.img-caption{
    font-size: calc(18rem / 16);
    line-height: 1;
}

.decorator {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    white-space: nowrap;
}
.decorator__icon {
    font-size: calc(170rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(480rem / 16);
    }
}
.decorator__icon + .decorator__icon {
    margin-left: calc(10rem / 16);
    font-size: calc(120rem / 16);

    @media screen and (min-width: 768px) {
        margin-left: calc(30rem / 16);
        font-size: calc(340rem / 16);
    }
}
.triangles-right {
    margin-left: auto;
    max-width: 30%;

    @media screen and (min-width: 768px) {
        margin-left: 0;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}

.grayscale-logo img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: .8;
    transition: filter 250ms ease-in-out, opacity 250ms ease-in-out;
}
.grayscale-logo:hover img {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
    opacity: 1;
}
