.slide {
    margin-top: calc(50rem/16);
    margin-bottom: calc(50rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(100rem / 16);
        margin-bottom: calc(100rem / 16);
    }
}

/* slides with background color / image */
.slide[class*='bg-'] {
    margin: 0;
    padding-top: calc(50rem / 16);
    padding-bottom: calc(50rem / 16);

    @media screen and (min-width: 768px) {
        padding-top: calc(100rem / 16);
        padding-bottom: calc(100rem / 16);
    }
}

.slide--full-height {
    margin: 0;
    padding-top: calc(60rem / 16);
    padding-bottom: calc(60rem / 16);
    position: relative;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 768px) {
        padding-top: calc(120rem / 16);
        padding-bottom: calc(120rem / 16);
    }
}

.slide--full-height .slide__content-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 50vh;

    @media screen and (min-width: 768px) {
        min-height: calc(100vh - calc(200rem / 16));
    }
}

/* slide head */
.slide__head {
    margin-bottom: calc(30rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(110rem / 16);
    }
}
.slide--full-height .slide__head {
    margin-bottom: 0;
}

.slide__scroll {
    position: absolute;
    top: 100%;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%) translateY(calc(-70rem / 16));
}