.slider-item {
    overflow: hidden;
}
.slick-slide > div {
    width: 100%;
}

/* slider arrows */
.slick-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0;
    transform: translateX(-100%) translateY(-50%);
    font-size: calc(20rem / 16);
    padding: 0 calc(10rem / 16);
    transition: color 200ms ease-in-out, opacity 200ms ease-in-out;
    color: var(--color-primary);
    line-height: 1;

    @media screen and (min-width: 768px) {
        font-size: calc(40rem / 16);
        padding: 0 calc(30rem / 16);
    }
}
.slick-arrow.slick-next {
    left: auto;
    right: 0;
    transform: translateX(100%) translateY(-50%);
}
.slick-arrow:focus {
    outline: none;
}
.slick-arrow:focus,
.slick-arrow:hover {
    color: var(--color-primary);
}
.slick-arrow.slick-disabled {
    opacity: 0;
    pointer-events: none;
}

/* slick dots */
.slick-dots {
    list-style: none;
    margin: calc(20rem / 16) 0 0;
    padding: 0;
    font-size: 0;
    text-align: center;
    width: 100%;
}
.slick-dots li {
    display: inline-block;
}
.slick-dots li:not(:last-child) {
    margin-right: calc(14rem / 16);
}
.slick-dots button {
    position: relative;
    border: 0;
    width: calc(14rem / 16);
    cursor: pointer;
    background: var(--color-dark-grey);
    height: calc(4rem / 16);
    border-radius: calc(2rem / 16);
}
.slick-active button {
    background: var(--color-primary);
}

.bg-secondary .slick-dots button,
.bg-primary .slick-dots button {
    background: #fff;
}
.bg-secondary .slick-dots .slick-active button,
.bg-primary .slick-dots .slick-active button {
    background: var(--color-default);
}

/* slider gutters */
[class*=" slider--gutter-"] .slick-track {
    display: flex;
}
[class*=" slider--gutter-"] .slick-slide {
    height: auto;
    display: flex;
}
[class*=" slider--gutter-"] .slick-slide .slider-item {
    height: 100%;
}
.slider--gutter-base .slick-list {
    margin: 0 calc(-7rem / 16);

    @media screen and (min-width: 768px){
        margin: 0 calc(-15rem / 16);
    }
}
.slider--gutter-base .slider-item {
    padding: 0 calc(7rem / 16);

    @media screen and (min-width: 768px){
        padding: 0 calc(15rem / 16);
    }
}

.slider-xs {
    @media screen and (max-width: 767px) {
        margin: 0;
    }
}