.thumb-slider {
    line-height: 0;
}
.thumb-slider__thumbs{
    margin-top: calc(6rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(14rem / 16);
    }
}
.thumb-slider__thumbs .slick-track {
    margin-left: 0;
    margin-right: 0;
}
.thumb-slider__thumbs .slick-list {
    margin: 0 calc(-3rem / 16);

    @media screen and (min-width: 768px) {
        margin: 0 calc(-7rem / 16);
    }
}
.thumb-slider__thumbs .slider-item {
    padding: 0 calc(3rem / 16);

    @media screen and (min-width: 768px) {
        padding: 0 calc(7rem / 16);
    }
}