.main-footer {
    padding: calc(30rem / 16) 0;
    font-size: calc(16rem / 16);
    line-height: calc(20/16);
    letter-spacing: calc(2.13rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(38rem / 16) 0;
    }
}
.main-footer a:hover {
    text-decoration: underline;
}