html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
    body {
        width: calc(982rem/16);
    }
}
.page-wrapper {
    overflow: hidden;
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
    .container--no-padding-xs {
        padding: 0;
        overflow: hidden;
    }
}
.container.container {
    max-width: calc(1740rem/16);
    width: 100%;
}
.container {
    padding-left: calc(30rem / 16);
    padding-right: calc(30rem / 16);

    @media screen and (min-width: 768px) {
        padding-left: calc(15rem / 16);
        padding-right: calc(15rem / 16);

    }
}
.container-md {
    max-width: calc(1424rem / 16);
    margin-left: auto;
    margin-right: auto;
}
.container-narrow {
    max-width: calc(835rem / 16);
    margin-left: auto;
    margin-right: auto;
}