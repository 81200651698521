.scroll-top {
    height: calc(60rem / 16);
    width: calc(60rem / 16);
    border-radius: 50%;
    background-color: rgba(255,255,255,.8);
    box-shadow: 0 calc(2rem / 16) calc(4rem / 16) 0 rgba(0,0,0,0.1);
    position: fixed;
    right: calc(10rem / 16);
    bottom: calc(10rem / 16);
}
.scroll-top__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(90deg);
    font-size: calc(18rem / 16);
}