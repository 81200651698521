.page-heading {
    padding-top: calc(80rem / 16);
    padding-bottom: calc(80rem / 16);

    @media screen and (min-width: 768px) {
        min-height: calc(100vh - 98px);
        padding-top: calc(150rem / 16);
        padding-bottom: calc(150rem / 16);
    }
}
.slide--full-height.page-heading {
    @media screen and (max-width: 767px) {
        padding-top: calc(80rem / 16);
    }
}
.page-heading + .slide {
    margin-top: 0;
}